<template>
  <BCard
    v-if="mscAll.length > 1"
    header-tag="h2"
    header-bg-variant="ams-gray"
    header-border-variant="ams-header-orange"
    header-text-variant="black"
    header-class="border-bottom highlight-border"
    align="left"
    class="m3 shadow mt-3"
  >
    <template slot="header">
      Classifications
    </template>
    <BRow>
      <BCol>
        <BTabs
          v-if="mscAll.length > 0"
          style="width:100%;"
        >
          <BTab
            v-if="msc3yr.length > 1"
            title="Last 3 Years"
          >
            <Msntable
              :rows="msc3yr"
              :limit="classShownLimit ? defaultCount : -1"
            />
          </BTab>
          <BTab
            v-if="mscAll.length > 1"
            title="All Time"
          >
            <Msntable
              :rows="mscAll"
              :limit="classShownLimit ? defaultCount : -1"
            />
          </BTab>
        </BTabs>
        <div v-else>
          <i>No classifications found for this journal</i>
        </div>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BButton
          v-if="mscAll.length > defaultCount"
          class="btn-ams-blue-dark indent-xs"
          @click="toggleClassTable()"
          :title="classShownLimit ? 'Show all MSC' : 'Show fewer MSC'"
        >
          <span v-if="classShownLimit">
            Show All
          </span>
          <span v-else>
            Show Less
          </span>
        </BButton>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <div class="indent-xs">
          *MSC=<BLink
            :to="{ name:'SearchMsc' }"
            target="_blank"
          >
            Mathematics Subject Classification
          </BLink>
        </div>
      </BCol>
    </BRow>
  </BCard>
</template>
<script>
import { formatNumber } from '@/utils'
// import URLGen from '@/utils/url-gen'
import { JournalAPI, SeriesAPI } from '@/js-api'

export default {
  name: 'ProfileMSCComponent',
  components: {
    Msntable: () => import(/* webpackChunkName: "journal_msntable" */ '@/components/journal/MsnTable.vue'),
  },
  // title: 'ProfileMSC',
  props: {
    groupId: {
      type: Number,
      required: true,
    },
    seriesId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      // Classifications
      mscAll: [
        ['MSC*', 'Description', 'Count'],
      ],
      msc3yr: [
        ['MSC*', 'Description', 'Count'],
      ],
      defaultCount: 10,
      classShownLimit: true,
      mscUrl: '/mathscinet/search/mscbrowse.html?pc=',
    }
  },
  computed: {
    yearRange: function() {
      const today = new Date()
      const endYear = today.getFullYear()
      const startYear = endYear - 3
      return [startYear, endYear]
    },
    isSeries: function() {
      return (this.seriesId && this.seriesId > -1)
    },
  },
  mounted: function() {
    this.getMSC()
  },
  methods: {
    getMSC: async function() {
      let result
      if (this.isSeries) {
        result = await SeriesAPI.classifications(this.seriesId)
      } else {
        result = await JournalAPI.classifications(this.groupId)
      }

      const pubC = {}
      if (this.isSeries) {
        if (result.results.length === 0) {
          // No classifications...
          return
        }
        pubC.c3yr = result.results[0].publicationsClassifications.lastThreeYears
        pubC.cAll = result.results[0].publicationsClassifications.allTime
      } else {
        if (result.length === 0) {
          // No classifications...
          return
        }
        pubC.c3yr = result[0].publicationsClassifications.lastThreeYears
        pubC.cAll = result[0].publicationsClassifications.allTime
      }

      this.msc3yr = this.msc3yr.concat(this.buildMSCTable(pubC.c3yr, true))
      this.mscAll = this.mscAll.concat(this.buildMSCTable(pubC.cAll, false))
    },

    buildMSCTable: function(data, yrRange) {
      let publicationsQuery = this.isSeries ? `si:${this.seriesId} ` : `gi:${this.groupId} `
      const mscRequestInfo = {}
      if (this.isSeries) {
        mscRequestInfo.seriesId = this.seriesId
      } else {
        mscRequestInfo.groupId = this.groupId
      }
      if (yrRange) {
        mscRequestInfo.yearRange = this.yearRange
        publicationsQuery += `y:[${this.yearRange[0]} ${this.yearRange[1]}] `
      }
      const rows = []
      for (const i in data) {
        mscRequestInfo.msc = data[i].msc
        const mscLink = {
          // url: URLGen.msc({ msc: data[i].msc }),
          to: {
            name: 'SearchMsc',
            query: {
              text: data[i].msc,
            },
          },
          text: data[i].msc,
          target: 'msc',
          attr: {
            title: 'MSC ' + data[i].msc + ' description and subcategories',
          },
        }
        rows.push(
          [
            mscLink,
            data[i].description.length > 0 ? data[i].description : 'Other',
            {
              // url: URLGen.publications(mscRequestInfo),
              to: {
                name: 'PublicationsSearch',
                query: {
                  query: publicationsQuery + `pc:${data[i].msc}`,
                },
              },
              text: formatNumber(data[i].count),
              target: 'msc',
              attr: {
                title: 'Publications that are part of MSC ' + data[i].msc + (yrRange ? ' over the last 3 years' : ''),
              },
            },
          ]
        )
      }
      return rows
    },
    toggleClassTable: function() {
      this.classShownLimit = !this.classShownLimit
    },
  },
}
</script>
